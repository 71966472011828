























































































import { Component, Vue } from 'vue-property-decorator'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import PasswordService from '@/services/PasswordService'
import LogoService from '@/services/LogoService'
import LayoutCompactFooterOnly from '@/layouts/LayoutCompactFooterOnly.vue'

const dict = {
  custom: {
    email: {
      regex: () => '「メールアドレス」を正しく入力してください。'
    }
  }
}

Validator.localize('jp', dict)

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    LayoutCompactFooterOnly
  }
})
export default class ForgotPassword extends Vue {
  public message: string = ''
  public image: any = ''

  private user: any = { email: '' }

  async created() {
    await LogoService.getAvatar().then((res: any) => {
      this.image = res.data.items
    })
  }

  public handleForgotPassowd() {
    if (this.user.email) {
      PasswordService.forgotPassword(this.user.email).then(
        (response: any) => {
          if (response.data.status) {
            this.message = 'success'
          }
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          if (error.response.status === 404) {
            this.message = '404'
          } else {
            this.message = 'errors'
          }
        }
      )
    } else {
      this.message = 'errorsEmail'
    }
  }
}
